import { Controller, useFormContext } from "saga-library/src/components/Form";
import React, { useEffect, useRef, useState } from "react";
import { useTheme, SxProps, Theme } from "@mui/material";
import { SimpleTextField } from "saga-library/src";
import { MultipleValuesBatchMessage } from "../../../../../utils/ABClaimConstants";
import Box from "@mui/material/Box";
import { SimpleTooltip } from "saga-library/src/components/Tooltip";

export interface TextFieldProps {
  name: string
  label: string
  sx?: SxProps<Theme>
  rules?: object
  inputProps?: object
  type?: 'tel' | 'email' | 'text' | 'password' | 'number'
  field: 'calls' | 'encounter' | 'claimAmount' | 'text'
  hideHelperText?: boolean
  multiline?: boolean
  rows?: number
  InputProps?: object
  InputLabelProps?: object
  fullWidth?: boolean
  multipleValuesState?: boolean
  dataTestId: string
}

export default function BatchControlledOverflowTextField({
   name,
   label,
   sx,
   rules,
   inputProps,
   type = 'text',
   hideHelperText,
   multiline,
   rows,
   InputProps,
   InputLabelProps,
   fullWidth = false,
   multipleValuesState,
   field,
  dataTestId
 }: TextFieldProps) {
  const { control, formState: { dirtyFields } } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const overflowingText = useRef<HTMLInputElement | null>(null);
  const theme = useTheme()

  const checkOverflow = (textContainer: HTMLInputElement | null): boolean => {
    if (textContainer) {
      return (textContainer.offsetWidth < textContainer.scrollWidth)
    }
    return false
  }

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true)
      return
    } else {
      setOverflowActive(false)
    }
  }, [])

  const batchInputStyle = {
    '& .MuiInputLabel-root': {
      color: (field === 'text' && dirtyFields.text) || (field === 'calls' && dirtyFields.calls) || (field === 'encounter' && dirtyFields.encounter) || (field === 'claimAmount' && dirtyFields.claimAmount) ? theme.palette.secondary.main : '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (field === 'text' && dirtyFields.text) || (field === 'calls' && dirtyFields.calls) || (field === 'encounter' && dirtyFields.encounter) || (field === 'claimAmount' && dirtyFields.claimAmount) ? theme.palette.secondary.main : '',
      }
    },
    ...sx
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value = '', ref } }) => {
        return (
          <>
            {
              (multipleValuesState) ?
                <SimpleTooltip title={MultipleValuesBatchMessage} open={tooltipOpen}>
                  <Box
                    display={'flex'}
                    sx={sx}
                  >
                    <SimpleTextField
                      name={name}
                      variant={'standard'}
                      label={label}
                      disabled={true}
                      value={field === 'text' ? 'Multiple values entered' : '[...]'}
                      InputProps={{
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        style: {
                          color: theme.palette.greys.medium,
                          fontSize: '14px',
                          marginLeft: '14px',
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          color: theme.palette.greys.light,
                          fontStyle: 'italic',
                          fontWeight: 500,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          marginLeft: '14px'
                        },
                        marginBottom: '16px',
                      }}
                      onMouseOver={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                      dataTestId={dataTestId}
                    />
                  </Box>
                </SimpleTooltip>
                :
                <SimpleTextField
                  name={name}
                  onChange={(data) => {
                    onChange(data)
                  }}
                  value={value || ''}
                  label={label}
                  type={type}
                  inputProps={inputProps}
                  hideHelperText={hideHelperText}
                  multiline={multiline}
                  rows={rows}
                  InputProps={InputProps}
                  InputLabelProps={InputLabelProps}
                  fullWidth={fullWidth}
                  inputRef={ref}
                  sx={batchInputStyle}
                  dataTestId={dataTestId}
                />
            }
          </>
        )
      }}
    />
  )
}
