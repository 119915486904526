import React, { useEffect, useState } from "react";
import { MultiSelect } from "saga-library/src";
import { Controller, FieldError, useFormContext } from 'saga-library/src/components/Form'
import { useTenantContext } from '../providers/TenantContextProvider'
import { Box, SxProps, Theme } from '@mui/material'

type PractitionerOptions = {
  key: string,
  id: string,
  name: string
}

export interface PractitionerAutocompleteMultiSelectProps {
  value?:Array<PractitionerOptions> | null
  onChange: (string) => void
  disabled?: boolean
  dataTestId: string
  sx?: SxProps<Theme>
  error?: FieldError
  helperText?: string
}

export const PractitionerAutocompleteMultiSelect = ({value, dataTestId, sx, ...props}:PractitionerAutocompleteMultiSelectProps) => {
  const { practitioners } = useTenantContext()
  const [selectedIds, setSelectedIds] = useState<PractitionerOptions[]>([])

  const options = [
    {key: 'allPractitioners', name: 'All practitioners', id: 'allPractitioners'},
    ...practitioners.map((practitioner) => {
      return {
        key: practitioner.id,
        name: `${practitioner.lastName}, ${practitioner.firstName}`,
        id: practitioner.id,
      }
    })
  ]

  useEffect(()=>{
    if(value){
      const allPractitionersSelected = value.length > 0 && value[value.length - 1].id === 'allPractitioners'
      const selectedIds: Array<any> = []

      if (allPractitionersSelected) {
        options.forEach((practitioner) => {
          selectedIds.push(practitioner)
        })
      } else {
        value.filter((practitioner) => practitioner.id !== 'allPractitioners').forEach((practitioner) => {
          selectedIds.push(practitioner)
        })
      }
      setSelectedIds(selectedIds)
    }
  }, [value])

  return (
    <Box sx={sx}>
      <MultiSelect
        dataTestId={dataTestId}
        placeholder={'Select practitioners'}
        selectAllId={'allPractitioners'}
        selectAllPlaceholder={'All practitioners'}
        options={options}
        value={selectedIds}
        {...props}
      />
    </Box>
  )
}

export interface ControlledPractitionerAutocompleteMultiSelectProps {
  name: string
  disabled?: boolean
  dataTestId: string
  sx?: SxProps<Theme>
}

export const ControlledPractitionerAutocompleteMultiSelect = ({name,disabled, dataTestId, sx}:ControlledPractitionerAutocompleteMultiSelectProps) =>{
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field: { value, onChange}, fieldState: { error }}) => {
        return <PractitionerAutocompleteMultiSelect
          onChange={onChange}
          value={value}
          disabled={disabled}
          dataTestId={dataTestId}
          sx={sx}
          error={error}
          helperText={error?.message}
        />
      }}
    />
  )
}
