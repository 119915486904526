import React from "react";
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { DiagnosticCodeSearch } from "../../../../../components/SearchControls/Claims/DiagnosticCodeSearch";
import { SxProps, Theme } from "@mui/material"

interface BatchControlledDiagnosticCodeInputProps {
  name: string
  label: string
  sx?: SxProps<Theme>
  serviceDate?: Date,
  practitionerId?: string
  dataTestId: string
}

export const BatchControlledDiagnosticCodeInput = ({
  name,
  label,
  sx,
  serviceDate,
  practitionerId,
  dataTestId
}: BatchControlledDiagnosticCodeInputProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <DiagnosticCodeSearch
      label={label}
      name={name}
      sx={{
        ...batchUpdateStyleSearch(dirtyFields.diagnosticCodes),
        ...sx
      }}
      serviceDate={serviceDate}
      practitionerId={practitionerId}
      dataTestId={dataTestId}
    />
  )
}
