import { Controller, useFormContext } from "saga-library/src/components/Form";
import { DosageType, PrescriptionDosageLink } from "../../../../../types/Prescription";
import { Box, Grid2 as Grid, Typography, useTheme } from "@mui/material";
import {
  AddButton,
  Autocomplete,
  Checkbox,
  ConfirmationDialog,
  RemoveButton,
  TextField
} from "saga-library/src";
import React, { useEffect, useState } from "react";
import { useStaticDataContext } from "../../../../../providers/StaticDataContext";
import { DictionarySelect } from "./DictionarySelect";
import { LinkSelect } from "../../../../../components/LinkSelect";
import { PrescriptionFormRow } from "./PrescriptionForm";
import { PitTerminologyType } from '../../../../../types/PitTerminology'

export const DosageManager = ({name, disabled=false, dataTestId}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<string | null>()
  const { control, setValue, getValues } = useFormContext();
  const defaultDosage:DosageType = {
    dosageRange: undefined,
    dosageUnits: undefined,
    durationAmount: undefined,
    durationUnits: undefined,
    frequency: undefined,
    prn: undefined,
    id:undefined,
    dosageOrder: 0,
    version: "0"
  }
  const addDosage = () => {
    const dosages = getValues(name)
    defaultDosage.id = `new_dosage_${crypto.randomUUID()}`
    defaultDosage.dosageOrder = dosages.length+1
    setValue(name, [...dosages, defaultDosage])
  }

  const removeDosage = (id) => {
    const dosages = getValues(name)
    const updatedDosages = dosages.filter(d => d.id !== id)
    setValue(name, updatedDosages)
    setOpenDeleteConfirmation(null)
  }

  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value = [] } }) => {
      return <Box sx={theme => ({
        borderRadius: 2,
        border: `1px solid ${theme.palette.greys.ui }`,
        p: 2,
        minWidth:"fit-content",
        my:1
      })}>
        <DosageList dataTestId={dataTestId} dosages={value} name={name} onRemove={(id)=>setOpenDeleteConfirmation(id)} setValue={setValue} disabled={disabled}/>
        {!disabled &&
          <AddButton
            dataTestId={`${dataTestId}-dosage`}
            onClick={addDosage}
            label={"Add dosage"}
          />
        }
        <ConfirmationDialog
          open={!!openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(null)}
          title={'Delete dosage?'}
          message={'This action cannot be undone.'}
          primaryAction={() => removeDosage(openDeleteConfirmation)}
          primaryLabel={'Delete'}
          dataTestId={`${dataTestId}-delete-confirmation`}
        />
      </Box>
    }} />
}

const DosageList = ({dataTestId, dosages, name, disabled, setValue, onRemove}) => {
  return <>
    <Grid container spacing={0}>
      <Grid size={7}>
        <Typography variant={'h5'} fontWeight={'bold'}>
          Dosage
        </Typography>
      </Grid>
      <Grid size={5}>
        <Typography variant={'h5'} fontWeight={'bold'} sx={{ml: 2}}>
          Duration
        </Typography>
      </Grid>
      {dosages.map((d, i) => <GridDosage dataTestId={`${dataTestId}-${i}`} name={`${name}.${i}`} setValue={setValue} onRemove={()=>onRemove(d.id)} key={`dosage_${d.id}`} disabled={disabled} showLink={i>0}/>)}
    </Grid>
  </>
}

const GridDosage = ({dataTestId, name, setValue, onRemove, disabled=false, showLink=false}) => {
  const { prescribedQuantityUnit, prescriptionDoseQuantityUnit, unitsOfTime } = useStaticDataContext()

  const onDosageQuantityUnitChange = (unit) => {
    // only copy dosage units to medication units if the unit exists in the medication units dropdown
    if (prescribedQuantityUnit[unit]) setValue('quantityUnits', unit)
  }

  const getDosageDictionary = (): { [id: number]: PitTerminologyType; } => {
    return Object.fromEntries(Object.values(prescriptionDoseQuantityUnit).filter(x => x.pitSubsetLinks && x.pitSubsetLinks.filter(link => link.status === 'Y').length > 0).map(x => [x.id, x]))
  }

  return <>
    { showLink && <Grid size={12}>
      <DosageLinkSelect dataTestId={`${dataTestId}-dosage-link-select`} />
    </Grid>}
    <Grid size={7}>
      <PrescriptionFormRow>
        <Box display={'flex'} flexGrow={0}>
          <NumberRange dataTestId={`${dataTestId}-number-range`} name={`${name}.dosageRange`} disabled={disabled}/>
          <DictionarySelect
            dataTestId={`${dataTestId}-dosage-units`}
            name={`${name}.dosageUnits`}
            label={'Units'}
            dictionary={getDosageDictionary()}
            onChange={(v)=> onDosageQuantityUnitChange(v)}
            sx={{
              width: '215px'
            }}
            disabled={disabled}
            sortOptions={'asc'}
          />
        </Box>
        <DosageFrequency dataTestId={`${dataTestId}-dosage-frequency`} name={`${name}.frequency`} disabled={disabled}/>
      </PrescriptionFormRow>
    </Grid>
    <Grid size={5}>
      <PrescriptionFormRow sx={{ml: 2, gap:1}}>
        <NumberInput dataTestId={`${dataTestId}-duration-amount`} name={`${name}.durationAmount`} label={"#"}  disabled={disabled}/>
        <DictionarySelect
          dataTestId={`${dataTestId}-duration-units`}
          name={`${name}.durationUnits`}
          label={'Units'}
          dictionary={unitsOfTime}
          sx={{width:'158px'}}
          disabled={disabled}
        />
        <Checkbox dataTestId={`${dataTestId}-prn`} label={'PRN'} name={`${name}.prn`} formControlLabelSx={{mt:1, mr:0}}  disabled={disabled}/>
        {(!disabled && onRemove) && <RemoveButton
          dataTestId={dataTestId}
          onClick={onRemove}
          sx={{
            my:1,
            ml:"auto",
            display:"block",
            width: "40px",
            justifySelf: "flex-end",
        }}/> }
      </PrescriptionFormRow>
    </Grid>
  </>
}

const DosageLinkSelect = ({dataTestId}) => {
  const options=[
    { label: 'then', value: PrescriptionDosageLink.THEN },
    { label: 'and', value: PrescriptionDosageLink.AND },
  ]
  return (
    <LinkSelect
      name={'link'}
      defaultValue={PrescriptionDosageLink.THEN}
      options={options}
      dataTestId={dataTestId}
    />
  )
}

const NumberInput = ({dataTestId, name, label, disabled=false}) => {
  return <TextField
    dataTestId={dataTestId}
    name={name}
    label={label}
    type={'number'}
    sx={{ width:"80px" }}
    disabled={disabled}
  />
}

const NumberRange = ({dataTestId, name, disabled=false}) => {
  return <TextField
    dataTestId={dataTestId}
    name={name}
    label={'#'}
    regexPattern={/^(?:\d{1,4}|\d{1,4}-$|\d{1,4}-\d{1,4})$/}
    disabled={disabled}
    sx={{ width:"80px", mr:1}}
  />
}

const DosageFrequency = ({dataTestId, name, disabled=false}) => {
  const { control, getValues } = useFormContext();
  const { frequencyData } = useStaticDataContext()
  const [frequencyOptions, setFrequencyOptions] = useState<{code: string, description: string}[]>(frequencyData)
  const frequencyRef = React.createRef<HTMLInputElement>()
  const [highlightStart, setHighlightStart] = React.useState<number|null>(null)
  const [inputValue, setInputValue] = React.useState<string>("")

  useEffect(() => {
    filterFrequencyOptions(getValues(name))
  }, [getValues, name])

  useEffect(()=>{
    if(highlightStart !== null){
      frequencyRef?.current?.focus()
      frequencyRef?.current?.setSelectionRange(highlightStart, highlightStart + 3)
      setHighlightStart(null)
    }
  }, [highlightStart])

  const updateHighlightStart = (value: string) => {
    if (!value) {
      return
    }

    if(value.includes('<x>')){
      setHighlightStart(value.indexOf('<x>'))
    } else if(value.includes('<y>')){
      setHighlightStart(value.indexOf('<y>'))
    }
  }

  const filterFrequencyOptions = (value?: string) => {
    setFrequencyOptions(value ? frequencyData.filter((fd) => fd.code === value) : frequencyData)
  }

  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => {
      return <Autocomplete
        dataTestId={dataTestId}
        label={"Frequency"}
        options={frequencyOptions}
        getOptionLabel={(option) => {
          if(typeof option === 'string') {
            return option
          }
          return option.code
        }}
        OptionComponent={FrequencyOption}
        optionComponentKey={"code"}
        onChange={(_, newValue, reason)=>{
          const updatedValue = newValue?.code || newValue
          onChange(updatedValue)
          updateHighlightStart(updatedValue)
          if (reason === "selectOption" || reason === "blur") {
            filterFrequencyOptions(updatedValue)
          }
        }}
        isOptionEqualToValue={(o, v) => {
          if (o.code === v) {
            return true
          }
          return (o.code === v.code)
        }}
        value={value}
        inputValue={inputValue}
        onInputChange={(_, newInputValue, reason) => {
          setInputValue(newInputValue)
          if (reason === "input" || reason === "clear") {
            filterFrequencyOptions()
          }
        }}
        onFocus={() => {
          updateHighlightStart(value)
        }}
        freeSolo={true}
        inputRef={frequencyRef}
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          width:"335px",
        }}
        disabled={disabled}
        error={error}
        helperText={error?.message}
      />
    }}
  />
}

const FrequencyOption = ({props, data}) => {
  const theme = useTheme()

  return <Box
    display={'flex'}
    flexDirection={'column'}
    m={1}
    gap={'2px'}
    {...props}
    sx={{
      alignItems:'flex-start !important'
    }}
  >
    <Typography variant={'p4'}> {data.code} </Typography>
    <Typography variant={'body2'} color={theme.palette.greys.medium}> {data.description} </Typography>
  </Box>
}