import React, { useEffect, useState } from "react";
import { MultiSelect, Spinner } from "saga-library/src";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { Box, SxProps, Theme } from "@mui/material";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { LIST_SCHEDULES } from "../graphql-definitions";

type ScheduleOptions = {
  key: string,
  id: string,
  name: string
}

export interface ScheduleAutocompleteSelectProps {
  value?:Array<ScheduleOptions> | null
  onChange: (string) => void
  disabled?: boolean
  sx?: SxProps<Theme>
}

export const ScheduleAutocompleteSelect = ({value, onChange, sx, disabled=false}:ScheduleAutocompleteSelectProps) => {
  const { tenant_id } = useParams()
  const [selectedIds, setSelectedIds] = useState<Array<string>>([])

  const { loading, data } = useQuery(LIST_SCHEDULES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
  })

  const schedules = _get(data, 'tenant.schedule.listSchedules', [])
  const options = [
    {key: 'allSchedules', name: 'All schedules', id: 'allSchedules'},
    ...schedules.map((schedule) => {
      return {
        key: schedule.id,
        name: `${schedule.practitionerLastName}, ${schedule.practitionerFirstName} (${schedule.location.name})`,
        id: schedule.id,
      }
    })
  ]

  useEffect(()=>{
    if(value){
      const allSchedulesSelected = value.length > 0 && value[value.length - 1].id === 'allSchedules'
      const selectedIds: Array<any> = []

      if (allSchedulesSelected) {
        options.forEach((schedule) => {
          selectedIds.push(schedule)
        })
      } else {
        value.filter((schedule) => schedule.id !== 'allSchedules').forEach((schedule) => {
          selectedIds.push(schedule)
        })
      }
      setSelectedIds(selectedIds)
    }
  }, [value])

  if(loading){
    return <Spinner size={'xs'}/>
  }

  return <Box
    sx={sx}>
    <MultiSelect
      dataTestId={'selectSchedules'}
      placeholder={'Select schedules'}
      selectAllId={'allSchedules'}
      selectAllPlaceholder={'All schedules'}
      disabled={disabled}
      options={options}
      onChange={onChange}
      value={selectedIds}
    />
  </Box>
}

export const ControlledScheduleAutocompleteSelect = ({name,disabled}) =>{
  const { control } = useFormContext();

  return <Controller
    name={name}
    control={control}
    render={({field: { value, onChange}}) => {
      return <ScheduleAutocompleteSelect
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    }
    }/>
}
