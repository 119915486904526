import React, { useEffect, useState } from "react";
import { Box, Divider, SxProps, Theme } from "@mui/material";
import {
  Button,
  DialogV2,
  EmptyList,
  FileUpload,
  FilterSelect,
  Section,
  LoadingButton
} from "saga-library/src";
import { LoadingSpinner } from "../../../../components/LoadingScreen";
import { useImportedDocumentsStateContext } from "./ImportedDocumentsStateProvider";
import { UnlinkedDocumentList } from "./UnlinkedDocumentList";
import { FileUploadOutlined } from "@mui/icons-material";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useDocumentContext } from "../../../../providers/DocumentProvider";

const filterOptions =
  [
    {
      label: "Unlinked",
      value: "unlinked"
    },
    {
      label: "Linked",
      value: "linked"
    }
  ]

interface DocumentListSectionProps {
  sx?: SxProps<Theme>
}

export const DocumentListSection = ({sx} : DocumentListSectionProps) => {
  const [ selectedFilter, setSelectedFilter ] = useState<string>('unlinked')
  const [ openImportDocumentDialog, setOpenImportDocumentDialog ] = useState<boolean>(false)
  const [ listLoading, setListLoading ] = useState<boolean>(false)
  const { refreshUnlinkedDocuments, refreshLinkedDocuments, linkedDocuments, unlinkedDocuments, setSelectedFile } = useImportedDocumentsStateContext()
  const showEmptyListMessageText = 'Documents appear here once imported.'

  useEffect(() => {
    if (!selectedFilter) return

    const fetchData = async () =>
    {
      setListLoading(true)
      if (selectedFilter === 'unlinked') await refreshUnlinkedDocuments()
      if (selectedFilter === 'linked') await refreshLinkedDocuments()
      setListLoading(false)
    }

    fetchData()
      .catch(console.error);

  }, [selectedFilter, refreshUnlinkedDocuments, refreshLinkedDocuments])

  useEffect(() => {
    if (selectedFilter === 'unlinked' && unlinkedDocuments.length === 0) setSelectedFile(null)
    if (selectedFilter === 'linked' && linkedDocuments.length === 0) setSelectedFile(null)
  }, [selectedFilter, unlinkedDocuments.length, linkedDocuments.length, setSelectedFile])

  const onFilterChange = async (e) => {
    setSelectedFilter(e.value)
  }

  const onImportDocumentsClick = () => {
    setOpenImportDocumentDialog(true)
  }

  const onImportDocumentsDialogClose = () => {
    setOpenImportDocumentDialog(false)
  }

  const documentListHeader = (
    <>
      <Box pb={1}>
        <FilterSelect
          dataTestId={"document-list-filter"}
          options={filterOptions}
          onSelect={onFilterChange}
          defaultSelectedValue={selectedFilter}
          size={"large"}
          sx={{justifyContent: 'left'}}
        />
      </Box>
      <Box>
        <Divider />
      </Box>
    </>
  )

  const list = () => {
    if (listLoading) {
      return <LoadingSpinner sx={{ flexGrow: 'inherit' }} size={"md"} />
    } else if (selectedFilter === 'unlinked') {
      if (unlinkedDocuments.length === 0) {
        return <EmptyList dataTestId={"unlinked-documents-empty-list"} size={'md'} sx={{mx:6}} message={showEmptyListMessageText} />
      }
      return <UnlinkedDocumentList dataTestId={'unlinked-documents-list'} items={unlinkedDocuments} />
    } else if (selectedFilter === 'linked') {
      if (linkedDocuments.length === 0) {
        return <EmptyList dataTestId={"linked-documents-empty-list"} size={'md'} sx={{mx:6}} message={showEmptyListMessageText} />
      }
      return <EmptyList size={'md'} message={'Not yet implemented'} />
      //return <LinkedDocumentList dataTestId={'linked-documents-list'} items={linkedDocuments} />
    }
  }

  return (
    <Section.Column
      preventScroll={true}
      sx={{
        height: 'auto',
        width: '289px',
        whiteSpace: 'nowrap',
        p: 1,
        flex: "0 0 auto",
        ...sx
      }}
      header={documentListHeader}
    >
      {list()}
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          justifyContent: "center",
          paddingRight: 1,
        }}
      >
        <Divider
          sx={{
            mt: 0,
            mb: 1,
          }}
        />
        <ImportDocumentsButton onClick={onImportDocumentsClick} />
      </Box>
      <ImportDocumentsDialog open={openImportDocumentDialog} onModalClose={onImportDocumentsDialogClose} />
    </Section.Column>
  )
}

const ImportDocumentsButton = ({onClick}) => {
  return (
    <Button
      dataTestId={'import-document-button'}
      variant={'outlined'}
      name={'import_document_button'}
      onClick={onClick}
      sx={{pl:2, pr:2}}
      startIcon={<FileUploadOutlined/>}
    >
      Import Documents
    </Button>
  )
}

const ImportDocumentsDialog = ({ open, onModalClose }) => {
  const { uploadFiles } = useDocumentContext()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { pendingFiles, setPendingFiles, refreshUnlinkedDocuments } = useImportedDocumentsStateContext()
  const [ uploading, setUploading ] = useState<boolean>(false)

  const handleClose = () => {
    onModalClose()
    setPendingFiles([])
  }

  const handleSubmit = () => {
    if (pendingFiles.length === 0) return

    setUploading(true)

    uploadFiles(pendingFiles, true)
      .then(() => {
        showSuccessAlert('Documents have been imported.')
      })
      .catch((error) => {
        showErrorAlert('Documents couldn\'t be imported.')
        console.error(error)
      })
      .finally(() => {
        refreshUnlinkedDocuments()
        handleClose()
        setPendingFiles([])
        setUploading(false)
      })
  }

  const importButton = (
    <LoadingButton
      name={'import_button'}
      onClick={handleSubmit}
      loading={uploading}
      variant={'contained'}
      dataTestId={'import-button'}
    >
      {'Import'}
    </LoadingButton>
  )

  return (
    <DialogV2
      dataTestId={'import-documents-dialog'}
      title={'Import Documents'}
      onClose={handleClose}
      primaryAction={handleSubmit}
      primaryLabel={'import'}
      overridePrimaryComponent={importButton}
      open={open}
    >
      <FileUpload dataTestId={'file-upload'} files={pendingFiles} setFiles={setPendingFiles} uploading={uploading} />
    </DialogV2>
  )
}