import Box from '@mui/material/Box'
import React, { MouseEventHandler } from 'react'
import { SxProps, Theme } from '@mui/material'

interface LogoProps {
  sx?: SxProps<Theme>
  onClick?: MouseEventHandler
}

export const Logo = ({ sx, onClick }: LogoProps) => {
  return (
    <Box onClick={onClick} sx={sx}>
      <img
        alt={'Saga'}
        style={{
          flexShrink: 0,
          minWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          objectFit: 'contain',
        }}
        src={'/saga-logo-color.png'}
      />
    </Box>
  )
}

export default Logo
