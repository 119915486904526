import {
  ConfirmationDialog,
  ControlledSearch,
  EntryTitle,
  Form,
  LoadingButton,
  RadioGroup,
  RemoveButton,
  ResultOptionsWrapper,
  Section,
  Select,
  TextField,
  Typography,
  useForm
} from "saga-library/src";
import React, { useCallback, useEffect, useMemo } from "react";
import { PatientSearch } from "../../../../components/SearchControls/Patient/PatientSearch";
import { FormProvider } from "react-hook-form";
import { MenuItem, Box, SxProps, Theme } from "@mui/material";
import moment from "moment-timezone";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { useImportedDocumentsStateContext } from "./ImportedDocumentsStateProvider";
import { DocumentCategory, LinkDocumentDetails, PagesToInclude, ReviewRequired } from "../../../../types/Document";
import { useImportedDocumentsActionContext } from "./ImportedDocumentsActionProvider";
import { FileType } from "../../../../types/tasks";
import FormattedDatePicker from "../../../../components/FormattedDatePicker";
import { schema } from "./util/validation"
import { getPageCount } from "../../../../components/FoxitPDFViewer/util/PageRange";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { GET_SEARCH_LINKED_DOCUMENT_DESCRIPTIONS } from "../../../../graphql-definitions";
import { ImportedDocumentsPrintButton } from './ImportedDocumentsPrintButton'
import { PagesToIncludeOptions } from '../../../patients/components/print/PrintPdfModal'
import { AssignedTo } from '../../../../components/AssignedTo'

const FORM_NAME = 'link_document_form'
const LINK_DOCUMENT_WIDTH = '442px'

const ReviewRequiredOptions = [
  {label: "Yes", value: ReviewRequired.YES},
  {label: "No", value: ReviewRequired.NO}
]

export const LinkDocumentSection = () => {
  const {
    selectedFile,
    setSelectedFile,
    unlinkedDocuments,
    refreshUnlinkedDocuments,
    refreshLinkedDocuments
  } = useImportedDocumentsStateContext()
  const { linkDocument, deleteDocument } = useImportedDocumentsActionContext()
  const { enableNavigationPrompt } = usePrompt()
  const { tenant_id } = useParams()
  const [ submitting, setSubmitting ] = React.useState<boolean>(false)
  const [ openDeleteConfirmation, setOpenDeleteConfirmation ] = React.useState<boolean>(false)
  const [ pageCount, setPageCount ] = React.useState<number>()
  const { pdfDocRef, pdfUIRef, loadingFile} = useImportedDocumentsStateContext()

  const formMethods = useForm<LinkDocumentDetails>({
    defaultValues: {
      fileId: '',
      patientId: '',
      category: null,
      description: '',
      documentDate: '',
      pagesToInclude: PagesToInclude.ALL,
      pageRange: undefined,
      reviewRequired: ReviewRequired.NO,
      assignedUserId: '',
      version: '0'
    },
    schema: schema,
    context: { pageCount: pageCount }
  })

  const { setFocus, reset, setValue, handleSubmit, watch, formState: { dirtyFields } } = formMethods

  const loadFile = useCallback(() => {
    if (selectedFile) {
      reset({})
      setValue('fileId', selectedFile.id)
      setValue('documentDate', moment(selectedFile?.audit.crePit).format('YYYY-MM-DD'))
      setValue('version', selectedFile.version ?? '0')
    }
  }, [reset, selectedFile, setValue])

  const category = watch('category')
  const reviewRequired = watch('reviewRequired')

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(()=>{
    loadFile()
  }, [loadFile])

  useEffect(() => {
    setPageCount(pdfDocRef?.current?.getPageCount())
  }, [loadingFile, pdfDocRef])

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id,
    category: category
  }), [tenant_id, category])

  const goToNextDocument = () => {
    const unlinkedFileIndex = unlinkedDocuments.findIndex((file) => file.id === selectedFile?.id)

    let nextFile : FileType | null = null
    if (unlinkedDocuments.length === 1) {
      //only one item in list
      return
    }
    else if (unlinkedFileIndex === unlinkedDocuments.length - 1) {
      //bottom of the list
      nextFile = unlinkedDocuments[unlinkedFileIndex - 1]
    }
    else {
      //top of the list
      nextFile = unlinkedDocuments[unlinkedFileIndex + 1]
    }

    setSelectedFile(nextFile)
  }

  const onDeleteDocument = async() => {
    deleteDocument(selectedFile!.id, selectedFile!.version)
      .then(() => {
        goToNextDocument()
        setOpenDeleteConfirmation(false)

        refreshLinkedDocuments()
        refreshUnlinkedDocuments()
      })
  }

  const onFormSubmit = handleSubmit(
      async(data) => {
        setSubmitting(true)

        if (data.pagesToInclude === PagesToInclude.CURRENT || data.pagesToInclude === PagesToInclude.RANGE) {

          const pageCount = pdfDocRef?.current?.getPageCount()
          if (!pageCount) return

          // we can't remove all pages from a pdf
          if (data.pagesToInclude === PagesToInclude.RANGE) {
            if (!data.pageRange) return
            if (pageCount <= getPageCount(data.pageRange)) {
              data.pagesToInclude = PagesToInclude.ALL
            }
          } else {
            if (pageCount === 1) {
              data.pagesToInclude = PagesToInclude.ALL
            }
          }
        }

        linkDocument(data)
          .then(async () => {
            setSubmitting(false)
            if (data.pagesToInclude === PagesToInclude.ALL) {
              reset({})
              goToNextDocument()
            } else {
              if (pdfDocRef){
                // Tell Foxit to reopen the file since the wrong first page is sometimes rendered after removing pages
                const file = await pdfDocRef.current?.getFile({flags: 0})
                if (file) {
                  await pdfUIRef?.current?.close()
                  const doc = await pdfUIRef?.current?.openPDFByFile(file)
                  if (doc) {
                    pdfDocRef.current = doc
                  }
                }
              }
              loadFile()
              setPageCount(pdfDocRef?.current?.getPageCount())
            }
            refreshLinkedDocuments()
            refreshUnlinkedDocuments()
          })
      },
      (errors) => {
        console.log(errors)
        setSubmitting(false)
      })

  if (!selectedFile) return null

  return <Section.Column
    sx={{flex: "0 0 auto", width: LINK_DOCUMENT_WIDTH}}
    rightPadding={1}
    headerLabel={'Link document'}
    headerProps={{
      primaryAction: <LoadingButton
        name={'linkDocument-link'}
        dataTestId={'linkDocument-link'}
        variant={'contained'}
        type={"submit"}
        form={FORM_NAME}
        loading={submitting}
      >
        Link
      </LoadingButton>,
      iconButton1: <ImportedDocumentsPrintButton />,
      iconButton2: <RemoveButton
        dataTestId={'linkDocument'}
        onClick={() =>setOpenDeleteConfirmation(true)} />,
    }}
  >
    <FormProvider {...formMethods}>
      <Form
        autoComplete={'false'}
        name={'link_document_form'}
        id={FORM_NAME}
        onSubmit={onFormSubmit}
      >
        <PatientSearch
          dataTestId={"linkDocument-patient"}
          name={'patientId'}
          label={"Patient"}
        />
        <Box display={'flex'} gap={2}>
          <CategorySelect dataTestId={"linkDocument-category"}/>

          <FormattedDatePicker
            dataTestId={"linkDocument-date"}
            label={'Date'}
            name={'documentDate'}
            sx={{width: "50%"}}
          />
        </Box>
        <ControlledSearch
          dataTestId={"linkDocument-description"}
          name={'description'}
          label={'Description'}
          loadingText={'Loading...'}
          freeSolo
          queries={{
            search: {
              gql: GET_SEARCH_LINKED_DOCUMENT_DESCRIPTIONS,
              get: (data) => _get(data, 'tenant.search.linkedDocuments.recentDescriptions', []) as string[],
              variables: queryVariables,
              fetchPolicy: 'network-only'
            },
          }}
          render={{
            value: (result) => result || "",
            option: (result, state) => {
              return (
                <ResultOptionsWrapper>
                  <EntryTitle
                    title={result}
                    searchText={state.inputValue}
                  />
                </ResultOptionsWrapper>
              )
            }
          }}
        />
        <Box display={'flex'} gap={1}>
          <Box sx={{flex: "1 0 auto"}}>
            <RadioGroup
              dataTestId={"linkDocument-pagesToInclude"}
              label={"Pages to include"}
              name={"pagesToInclude"}
              onChange={(e)=> {
                if (e.currentTarget.value === PagesToInclude.RANGE) {
                  setFocus("pageRange")
                }
              }}
              row={true}
              sx={{ml: 1}}
              options={PagesToIncludeOptions}
            />
          </Box>
          <TextField
            dataTestId={"linkDocument-pageRange"}
            name={"pageRange"}
            label={"Pages"}
            placeholder={"E.g.: 1, 4-8"}
            sx={{mt: "20px"}}
            onChange={(e) => setValue("pagesToInclude", PagesToInclude.RANGE)}
            extraEndAdornment={
              <>
                <Typography>of&nbsp;</Typography>
                <Typography fontWeight={"bold"}>{pageCount}</Typography>
              </>
            }
          />
        </Box>
        <RadioGroup
          dataTestId={"linkDocument-reviewRequired"}
          label={"Review required?"}
          name={"reviewRequired"}
          row={true}
          sx={{ml: 1, justifyContent: "flex-start"}}
          options={ReviewRequiredOptions}
        />
        {reviewRequired === ReviewRequired.YES && (
          <AssignedTo
            name={"assignedUserId"}
            label={"Assigned to"}
            dataTestId={"linkDocument-assignedTo"}
          />
        )}
      </Form>
    </FormProvider>

    <ConfirmationDialog
      title={"Delete document?"}
      message={"This action cannot be undone."}
      open={openDeleteConfirmation}
      primaryLabel={"Delete"}
      primaryAction={onDeleteDocument}
      onClose={()=>setOpenDeleteConfirmation(false)}
    />
  </Section.Column>
}


export const CategorySelect = ({
  label = 'Category',
  name = 'category',
  onChange,
  sx,
  fullWidth=true,
  dataTestId,
  inputRef
}: {
    label?:string,
    name?:string,
    onChange?:(value) => void
    sx?:SxProps<Theme>,
    fullWidth?:boolean,
    dataTestId?:string,
    inputRef?:React.Ref<any>
  }) => {
    return <Select
      dataTestId={dataTestId}
      name={name}
      label={label}
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        }
      }}
      sx={sx}
      fullWidth={fullWidth}
      inputRef={inputRef}
    >
      <MenuItem data-testid={`${dataTestId}-menuItem-0`} value={DocumentCategory.CHART_NOTE}> Encounter note </MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-1`} value={DocumentCategory.FORM}> Form </MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-2`} value={DocumentCategory.INVESTIGATION}> Investigation </MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-3`} value={DocumentCategory.LAB_RESULT}> Lab result </MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-4`} value={DocumentCategory.LETTER}> Letter </MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-5`} value={DocumentCategory.OTHER_DOCUMENT}> Other document </MenuItem>
    </Select>
}