import * as React from 'react'
import Box from '@mui/material/Box'
import { TimePicker, LocalizationProvider, TimePickerSlotProps } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { FormHelperTextProps, InputLabelProps, SxProps, TextFieldProps, Theme } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import { InputProps, SlotButtonProps, SlotIconProps } from "saga-library/src";

export interface ControlledTimePickerInterface {
  label: string
  name: string
  sx?: SxProps<Theme>,
  onChange?: (v) => void
  format?: string
  disabled?: boolean
  dataTestId?: string
}

export const ControlledTimePicker = ({
  label,
  name,
  sx,
  onChange,
  format = "hh:mm A",
  disabled = false,
  dataTestId
}: ControlledTimePickerInterface) => {
  const { control } = useFormContext();
  return (
    <Box sx={{ m: theme => theme.spacing(1, 0), ...sx }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange : controlledOnChange, value, ref }, fieldState: {error} }) => {
          return (
            <SimpleTimePicker
              dataTestId={dataTestId}
              label={label}
              value={value}
              onChange={(v) => {
                if (onChange) onChange(v)
                controlledOnChange(v)
              }}
              format={format}
              error={error}
              helperText={error?.message}
              sx={sx}
              inputRef={ref}
              disabled={disabled}
            />
          )}
        }
      />
    </Box>
  )
}

const SimpleTimePicker = ({ label, value, onChange, helperText, error, format, sx, inputRef: _inputRef, disabled = false, dataTestId }) => {
  const defaultRef = React.useRef<HTMLInputElement>(null)
  let inputRef = _inputRef != null ? _inputRef : defaultRef

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        data-testid={dataTestId}
        label={label}
        value={moment(value)}
        onChange={(date, state) => {
          onChange(date, state)
        }}
        closeOnSelect={false}
        disabled={disabled}
        inputRef={inputRef}
        format={format}
        slotProps={{
          textField: {
            "data-testid": `${dataTestId}-textField`,
            size: 'small',
            helperText: helperText,
            error: error,
            sx: {
              '& .MuiInputBase-root': { height: '40px', borderRadius: '8px' },
              ...sx
            },
            InputLabelProps: {
              "data-testid": `${dataTestId}-label`
            } as InputLabelProps,
            inputProps: {
              "data-testid": `${dataTestId}-inputField`
            },
            InputProps: {
              "data-testid": `${dataTestId}-input`
            } as InputProps,
            FormHelperTextProps: {
              "data-testid": `${dataTestId}-helperText`
            } as FormHelperTextProps
          } as TextFieldProps,
          openPickerButton: {
            "data-testid": `${dataTestId}-openPickerButton`,
            sx: {
              '&:hover': {
                backgroundColor: 'backgrounds.hover',
              },
            }
          } as SlotButtonProps,
          clearIcon: {
            "data-testid": `${dataTestId}-clearIcon`
          } as SlotIconProps,
          leftArrowIcon: {
            "data-testid": `${dataTestId}-leftArrowIcon`
          } as SlotIconProps,
          nextIconButton: {
            "data-testid": `${dataTestId}-nextIconButton`
          } as SlotButtonProps,
          openPickerIcon: {
            "data-testid": `${dataTestId}-openPickerIcon`
          } as SlotIconProps,
          previousIconButton: {
            "data-testid": `${dataTestId}-previousIconButton`
          } as SlotButtonProps,
          rightArrowIcon: {
            "data-testid": `${dataTestId}-rightArrowIcon`
          } as SlotIconProps
        } as TimePickerSlotProps<moment.Moment, any>}
      />
    </LocalizationProvider>
  );
}

export default ControlledTimePicker
