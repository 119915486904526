import React, { useState } from "react";
import { MultipleValuesTextField } from "saga-library/src/components/TextField/TextField";
import { SxProps, Theme } from "@mui/material"

export interface MultipleValuesFieldProps {
  label: string
  children: React.ReactElement
  multipleValuesState: boolean
  setUpdatedFields: () => void
  sx?: SxProps<Theme>
  dataTestId?: string
}

export const MultipleValuesField = ({
  label,
  children,
  multipleValuesState,
  setUpdatedFields,
  sx,
  dataTestId,
}: MultipleValuesFieldProps) => {
  const [showComponent, setShowComponent] = useState(false)

  return (
    <>
    {
    (multipleValuesState && !showComponent) ?
      <MultipleValuesTextField
        dataTestId={`${dataTestId}-multipleValuesTextField`}
        label={label}
        fullWidth={true}
        onKeyDown={(e) => {
          if (e.keyCode === 8 || e.keyCode === 46) {
            setUpdatedFields()
            setShowComponent(true)
          }
        }}
        sx={sx}
      />
      :
      React.cloneElement(children, {autoFocus: showComponent, "data-testid": `${dataTestId}-singleValueTextField`})
    }
    </>
  )
}