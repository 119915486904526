import React from 'react'
import { SimpleTextField } from '../TextField'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import formatStringByPattern from 'format-string-by-pattern'
import { SxProps, Theme } from "@mui/material";

export interface PhoneFieldProps {
  name: string
  label: string
  sx?: SxProps<Theme>
  rules?: object
  inputProps?: object
  disabled?: boolean
  dataTestId?: string
}

const pattern = '(999) 999-9999'
const typeLength = 10
const regex = /[^\d]/g

export const phoneNumberMask = (value, extension?) => {
  let phoneNumber = value ? formatStringByPattern(pattern, value.replace(regex, '')) : value
  if (extension) {
    phoneNumber += ` ext. ${extension}`
  }
  return phoneNumber
}

export const PhoneField = ({
  name,
  label,
  sx,
  disabled = false,
  dataTestId
}: PhoneFieldProps) => {
  const { control } = useFormContext();

  const unmask = (value) => value.replace(regex, '').substring(0, typeLength)

  const update = (event, field) => {
    const unmaskedValue = unmask(event.target.value)
    field.onChange({ target: { value: unmaskedValue } })
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field , fieldState: {error}}) => (
        <SimpleTextField
          name={name}
          dataTestId={dataTestId}
          onChange={(e) => update(e, field)}
          value={phoneNumberMask(field.value)}
          helperText={error?.message}
          error={!!error}
          sx={sx}
          label={label}
          slotProps={{ htmlInput: { maxLength: pattern.length } }}
          inputRef={field.ref}
          disabled={disabled}
        />
      )}
    />
  )
}

export default PhoneField

export const PhoneValidation = {
  regex: /(^$)|^[0-9]{10,15}$/g,
}
