import React from "react";
import Table from '@mui/material/Table'
import { SxProps, TableBody, Theme } from "@mui/material";
import { StyledTableContainer } from "./TableContainer"
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { DraggableTableListRow } from "./DraggableTableListRow";
import { DraggableTableListHeader } from "./DraggableTableListHeader"
import { TableListHeaderConfig, TableListRowConfig, EmptyOrLoading } from "../TableList"
import { EmptyListConfig } from "../EmptyList/EmptyList";


type GenericBase = {
  id: string
}

interface DraggableTableListProps<Type> {
  columns: TableListHeaderConfig[]
  emptyListComponent?: EmptyListConfig
  size?: 'medium' | 'small'
  showHeader?: boolean
  sx?: SxProps<Theme>
  loading?: boolean
  onDragEnd?: ( moved: Type, index: number, listItems?: Type[]) => void
  listItems: Type[] | null
  updateListItems?: (L: Type[]) => void
  configureRow: (t: Type) => TableListRowConfig
  dataTestId?: string
}

const DropComponent = ({ children, ...props }) => {
  // This is to fix an issue with React 18 being in Strict mode preventing the drag and drop from working
  // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1167427762
  // https://www.freedium.cfd/https://medium.com/@wbern/getting-react-18s-strict-mode-to-work-with-react-beautiful-dnd-47bc909348e4
  const [ enabled, setEnabled ] = React.useState(false);

  React.useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <Droppable  {...props}>
      {children}
    </Droppable>
  );
}

export const DraggableTableList = <Type extends GenericBase>({
  columns,
  emptyListComponent,
  size = 'medium',
  showHeader = true,
  sx = {},
  loading = false,
  onDragEnd = (event) => {console.error("No onDragEnd handler provided.")},
  listItems = [],
  updateListItems = () => {console.error("No updateListItems handler provided.")},
  configureRow,
  dataTestId
}: DraggableTableListProps<Type>) => {

  const showTableContent = (listItems && listItems.length > 0) && !loading

  const renderItem = (item, index) => {
    if (!listItems) return null
    const row = configureRow(item)
    return (
      <Draggable
        draggableId={index.toString()}
        index={index}
        key={index}
        data-testid={`${dataTestId}-draggableTableList`}
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <DraggableTableListRow
            cells={ row.rowData }
            key={ 'row-' + index }
            onClick={ row.onClick }
            draggableProvided={provided}
            draggableSnapshot={snapshot}
            dataTestId={`${dataTestId}-draggableTableList-row-${index}`}
          />
        )}
      </Draggable>
    )
  }

  const onDragEndHandler = (event) => {
    if (listItems == null) return
    let newList = [...listItems]
    let movedItem = newList.splice(event.source.index, 1)[0]
    newList.splice(event.destination.index, 0, movedItem)
    updateListItems(newList)
    onDragEnd(movedItem, event.destination.index, newList)
  }

  return (
    <StyledTableContainer style={sx}>
      <Table size={size} sx={{flexShrink: 0}} stickyHeader data-testid={`${dataTestId}-draggableTableList-table`}>
        <DraggableTableListHeader columns={columns} showHeader={showHeader} />
        {showTableContent &&
          <DragDropContext onDragEnd={onDragEndHandler}>
            <DropComponent droppableId={"droppable"}>
              {(provided: DroppableProvided) => (
                <TableBody
                  ref={provided.innerRef}
                  key={'tableListBody'}
                  data-testid={`${dataTestId}-tableBody`}
                  {...provided.droppableProps}
                >
                  {listItems.map(renderItem)}
                  {provided.placeholder}
                </TableBody>)}
            </DropComponent>
          </DragDropContext>
        }
      </Table>

      <EmptyOrLoading
        showTableContent={showTableContent}
        emptyListComponent={emptyListComponent}
        loading={loading}
        dataTestId={`${dataTestId}-draggableTableList`}
      />
    </StyledTableContainer>
  )
}




export default DraggableTableList
