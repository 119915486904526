import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { ServiceCodeSearch } from "../../../../../components/SearchControls/Claims/ServiceCodeSearch";
import { SxProps, Theme } from "@mui/material"
interface BatchControlledServiceCodeSearchProps {
  name: string
  label?: string
  serviceDate?: Date,
  practitionerId?: string
  sx?: SxProps<Theme>
  dataTestId: string
}

export const BatchControlledServiceCodeSearch = ({
  name,
  label = 'Service code',
  practitionerId,
  serviceDate,
  sx,
  dataTestId
}: BatchControlledServiceCodeSearchProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <ServiceCodeSearch
      label={label}
      name={name}
      practitionerId={practitionerId}
      serviceDate={serviceDate}
      sx={{
        ...batchUpdateStyleSearch(dirtyFields.serviceCode),
        ...sx
      }}
      dataTestId={dataTestId}
    />
  )
}