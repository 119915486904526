import { Box, SxProps, SvgIcon, Theme } from "@mui/material";
import { Typography, Button } from "saga-library/src"
import React from "react";

export interface BannerProps {
  messageText?: string,
  message?: any,
  Icon?: typeof SvgIcon,
  actionButton?: React.ReactNode,
  action?: () => void,
  actionText?: string,
  backgroundColor?: string,
  textColor?: string,
  actionTextColor?: string,
  sx?: SxProps<Theme>,
  dataTestId?: string
}

export const Banner = ({
  messageText,
  message,
  Icon,
  actionButton,
  action,
  actionText,
  backgroundColor = 'backgrounds.info',
  textColor = 'primary.dark',
  actionTextColor = 'primary.main',
  sx,
  dataTestId
} : BannerProps) => {

  const defaultActionButton = <Button
    name={"banner_action"}
    onClick={action}
    variant={'text'}
    sx={{height: '28px', px: 1, color: actionTextColor}}
    dataTestId={`${dataTestId}-action`}
  >
    {actionText}
  </Button>

  const actionComponent=
              actionButton ? actionButton :
    (action && actionText) ? defaultActionButton : null

  return <Box
    display={'flex'}
    sx={{
      height: '44px',
      boxSizing: 'border-box',
      width: "100%",
      borderRadius: '8px',
      backgroundColor: backgroundColor,
      py: 1,
      px: 2,
      alignItems: 'center',
      ...sx
    }}
    data-testid={dataTestId}
  >
    <Box
      display={'flex'}
      sx={{
        gap: '10px',
        flex: '1 1 auto',
        pr: 2,
        alignItems: 'center'
      }}
    >
      {Icon && <Icon
        sx={{ color: textColor }}
        data-testid={`${dataTestId}-icon`}
      />}
      {message && message}
      {messageText &&
        <Typography
          color={textColor}
          sx={{ flex: '1 1 auto' }}
          dataTestId={`${dataTestId}-message`}
        >
          {messageText}
        </Typography>
      }
    </Box>
    {actionComponent}
  </Box>
}

export default Banner