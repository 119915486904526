import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, Chip, SxProps, Theme } from "@mui/material"
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete";
import { Controller, useWatch, useFormContext } from "saga-library/src/components/Form";
import { useParams } from 'react-router-dom'
import { TextField } from "saga-library/src";
import {
  feeModUnitDescriptionList,
  serviceCodeFeeModifierMaxSelection
} from "../../utils/ABClaimConstants";
import { AutocompleteTextField } from "saga-library/src/components/TextField/TextField";
import { FormHelperTextWithWarning } from "saga-library/src/components/FormHelperTextWithWarning";
import { EntryDescription, EntryTitle } from "saga-library/src";
import BatchControlledTextField from "../../apps/billing/components/BatchUpdate/components/BatchControlledTextField";
import { AbClaimMultiple } from '../../types/billing';
import { GET_SERVICECODEFEEMODIFIERS } from "../../graphql-definitions";
import _get from "lodash/get";
import { AbClaimServiceCodeFeeModifier } from '../../types/billing';
import { IdEqual } from 'saga-library/src/components/SearchControl/ControlledSearchControl'

interface ServiceFeeModifierSelectProps {
  serviceCodeId: string | null | undefined
  name?: string
  multiple?: boolean
  label?: string
  sx?: SxProps<Theme>
  inputProps?: object
  autoFocus?: boolean
  batchSx?: SxProps<Theme>
  batchUpdateMultiple?: AbClaimMultiple
  onChange?: () => void
  disabled?: boolean
  dataTestId?: string
}

interface ServiceFeeModifierOption {
  feeMod: string
  feeModType: string
  id: string
  description: string
  unitDescription: string
}

export const ServiceFeeModifierSelect = ({
  serviceCodeId,
  name = 'serviceCodeFeeModifiers',
  multiple = true,
  label = 'Fee modifiers',
  sx,
  autoFocus,
  batchSx,
  batchUpdateMultiple,
  onChange,
  disabled = false,
  dataTestId
}: ServiceFeeModifierSelectProps) => {
  const { control, setValue, register } = useFormContext();
  const [serviceFeeModifiers, setServiceFeeModifiers] = useState<ServiceFeeModifierOption[]>([])
  const [options, setOptions] = useState<ServiceFeeModifierOption[]>([])
  const { tenant_id } = useParams()

  const [getDataQuery, { loading }] = useLazyQuery(GET_SERVICECODEFEEMODIFIERS, {
    fetchPolicy: 'cache-first',
    variables: {
      serviceCodeId: serviceCodeId,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      const listOfModifiers = _get(data, 'tenant.abClaim.listAbClaimServiceCodeFeeModifiers', [])
      let tempOptions = listOfModifiers.map(({ id, feeMod, feeModType, description }, index) => {
          return {
            key: index,
            feeMod: feeMod,
            feeModType: feeModType,
            id: id,
            description: description
          }
        }
      )
      setOptions(tempOptions)
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  useEffect(() => {
    if (serviceCodeId) {
      getDataQuery()
    } else {
      setServiceFeeModifiers([])
    }
  }, [serviceCodeId])

  let [serviceCodeFeeModifiers] = useWatch({ name: ["serviceCodeFeeModifiers"], control})
  useEffect(() => {
    const feeMods = serviceCodeFeeModifiers?.map((fm, index) => {
      return {
        key: index,
        feeMod: fm.feeMod,
        feeModType: fm.feeModType,
        id: fm.id,
        description: fm.description,
        unitDescription: feeModUnitDescriptionList.find(
          (d) => d.feeMod === fm.feeMod
        )?.unitDescription,
      }
    }) || []
    setServiceFeeModifiers(feeMods)
  }, [serviceCodeFeeModifiers])

  return (
    <Box sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange : controlledOnChange, value, ref }, fieldState: {error} }) => {
          return (
            <FormControl fullWidth>
              <InputLabel data-testid={`${dataTestId}-label`} error={!!error} />
              <Autocomplete
                data-testid={dataTestId}
                multiple={multiple}
                loading={loading}
                options={options}
                value={value || []}
                disabled={disabled}
                getOptionLabel={(option) => {
                  return option.feeMod
                }}
                onChange={(_, data) => {
                  if (onChange) {
                    onChange()
                  }
                  else {
                    controlledOnChange(data)
                  }
                }}
                getOptionDisabled={(option) =>
                  (
                    value?.length === serviceCodeFeeModifierMaxSelection ||
                    value?.includes(option.id)
                  )
                }
                renderInput={(params) => (
                  <AutocompleteTextField
                    dataTestId={`${dataTestId}-textField`}
                    params={params}
                    name={name}
                    value={params.id}
                    label={label}
                    size={'small'}
                    autoFocus={autoFocus}
                    inputRef={ref}
                    disabled={disabled}
                    placeholder={value?.length ? undefined : "Select up to three" }
                  />
                )}
                renderOption={(props, option, state) => {
                  return Option(props, option, state, dataTestId)
                }}
                renderTags={(tagValue, getTagProps) => (
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.feeMod}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                )}
                isOptionEqualToValue={IdEqual}
                sx={batchSx}
              />
              <FormHelperText error={error?.type === "error"}>
                {error?.message}
              </FormHelperText>
              <FormHelperTextWithWarning error={error} helperText={error?.message} />
            </FormControl>
          )
        }}
      />
      {serviceFeeModifiers.map((f, index) => {
        if (!!f.unitDescription) {
          if (batchUpdateMultiple) {
            return (
              <BatchControlledTextField
                dataTestId={`${dataTestId}-multipleValuesTextField-${index}`}
                key={index}
                type={'number'}
                name={`feeMod${index + 1}Units`}
                label={f.unitDescription}
                setUpdatedFields={() => setValue(`feeMod${index + 1}Units`, '', { shouldDirty: true })}
                sx={{ mt: 1, mb: 1, width: '100%' }}
                InputProps={register(`feeMod${index + 1}Units`, { valueAsNumber: true })}
                multipleValuesState={batchUpdateMultiple[`feeMod${index + 1}Units`]}
              />
            )
          }
          else {
            return (
              <>
                <TextField
                  dataTestId={`${dataTestId}-singleValueTextField`}
                  key={index}
                  type={'number'}
                  label={f.unitDescription}
                  sx={{ my: 1, width: '100%' }}
                  name={`feeMod${index + 1}Units`}
                />
              </>
            )
          }
        }
      })}
    </Box>
  )
}

const Option = (props, option: AbClaimServiceCodeFeeModifier, state, dataTestId) => {
  return (
    <Box component={"span"} key={option.id}>
        <Box component="li" data-testid={`${dataTestId}-option-${state.index}`} {...props}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EntryTitle
              title={option.feeMod}
              searchText={state.inputValue}
            />
            <EntryDescription
              text={option.description}
              searchText={state.inputValue}
              maxLines={2}
            />
          </Box>
        </Box>
      </Box>
  )
}

export default ServiceFeeModifierSelect
