import React from 'react'
import { Box, useTheme, SxProps, Theme } from "@mui/material";
import { Button, Typography } from "saga-library/src";
import { useAccountContext } from "../providers/AccountContext";
import { useNavigate, useParams } from "react-router-dom";
import { FooterLayout } from "./Layouts";

interface AccessDeniedMessageProps {
  sx?: SxProps<Theme>
}

export const AccessDeniedMessage = ({ sx }: AccessDeniedMessageProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        mb: 4,
        ...sx
      }}
    >
      <Typography variant={'h1'} sx={{ mb: 4 }}>Access denied</Typography>
      <Typography variant={'subtitle1'}>You don't have permission to view this page.</Typography>
      <Typography variant={'subtitle1'} >Reach out to your Saga Health administrator for assistance.</Typography>
    </Box>
  )
}

export const AccessDeniedPage = () => {
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id } = useParams()
  const theme = useTheme()

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.tertiary.main,
      }}
    >
      <AccessDeniedMessage />
      <Button name={'dashboard'} onClick={() => navigate(buildTenantRoute(``, tenant_id))}>GO TO SAGA DASHBOARD</Button>
      <FooterLayout />
    </Box>
  )

}